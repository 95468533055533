import { useCallback } from "react";
import seq, { SeqLogLevel } from 'seq-logging';
import { usePathname } from "./use-pathname";

export const useLogger = () => {
    const pathname = usePathname();

    const logger = new seq.Logger({
        serverUrl: process.env.REACT_APP_SEQ_URI,
        apiKey: process.env.REACT_APP_SEQ_API_KEY,
        onError: (e => {
            console.error(`An error occurred whilst connecting to Seq:\n`, e)
        }),
    });

    // re: level type below; I didn't realise React had lookup types...  clever.  But definition is just string, so fuck that.
    // const log = useCallback((message: string, level: SeqEvent["level"] = 'Information', properties: object = {}): void => {
    const log = useCallback((message: string, level: SeqLogLevel = 'Information', properties: object = {}): void => {
        if (
            level === "Debug" 
            && process.env.NODE_ENV === 'production'
            && !process.env.REACT_APP_DEBUG_LOGGING
        )
            return;

        logger.emit({
            timestamp: new Date(),
            level,
            messageTemplate: message,
            properties: { pathname, ...properties, env: process.env.NODE_ENV }
        });
    }, [logger, pathname]);

    return [log];
};

